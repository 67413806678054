<template>
  <section>
    <CoinTable />
  </section>
</template>

<script>
import CoinTable from "../components/CoinTable";

export default {
  name: "Ticker",
  components: { CoinTable },
};
</script>
